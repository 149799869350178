import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header className="feuery-header">
    <div className="feuery-header__link-container">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/introduction">Introduction</Link></li>
        <li><Link to="/fieldguides">Field Guides</Link></li>
        <li><Link to="/map">Map</Link></li>
        <li><Link to="/credits">Credits</Link></li>
        <li><Link to="/contacts">Contact Us</Link></li>
        <li><Link to="/stories">Stories</Link></li>
      </ul>
    </div>
  </header>
)

export default Header
