import React from 'react'
import Image from 'gatsby-image'
import Layout from '../components/layout'

const Story = ({ pageContext }) => {
  const { title, content, illustration } = pageContext;

  return <Layout>
    <div className="story-container">
      <Image fluid={illustration.fluid} />
      <h1 className="story-title-text">{title}</h1>
      <div className="story-body" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </Layout>
}

export default Story